/* =========================================================================== */
/* =======                                                             ======= */
/* =======                  CSS du site client                         ======= */
/* =======            Variables de customisation du theme              ======= */
/* =======                  Client site CSS                            ======= */
/* =======            Client theme customisation theme                 ======= */
/* =======                                                             ======= */
/* =========================================================================== */

/**
 * Table of content

    $Accessibility
    $Internationalisation
    $Images
    $Default Theme Customisation
    $Default Fonts
    $Default Colors
    $Child Theme
    $Effects
    $Compatibility

 */



/* ==========================================================================
   $Accessibility
   ========================================================================== */

/* teste l'accessibilité en local avec CSS : entoure toutes les mauvaises pratiques */
/* laissez en commentaire pour la mise en prod !!! */
//@import "scss/a11y/a11y-fr";


/* ==========================================================================
   $Internationalisation
   ========================================================================== */

/* Un bien grand mot ! solution de secours pour les textes multilingues en dur dans master.html */
/* laissez en commentaire si pas besoin de ce patch */
@import "scss/l18n/l18n.scss";


/* ==========================================================================
   $Images
   ========================================================================== */

$logo-client: "img/logo.png";
$header-img: "img/header.png";
$showcase: "img/showcase.jpg";


/* ==========================================================================
   $Default Theme Customisation
   ========================================================================== */

// client colors

// global color
$dark-theme:                  false;    // true => content on dark background

// Main breakpoint 
$mbp:                         767px;
$mbp-up:                      768px;

// Header options
$header-dark:                 false;     // true => dark bg or false => light bg
$text-accueil:                true;     // textual home button vs .fa icon
$container-header:            100%;     // header width
$min-container-header:        100%;     // header min-width
$container-width:             768px;   // container max-width, must be > $mbp-up
$header-icon:                 false;    // header social links display 
$header-language-active-hide: false;    // Language .active hidden

// Logo and nav layout
$logo-align:                  "center";   // "left"(default), "right" or "center" for desktop header (with "center" value, set higher $burger-active-width to keep 1 line menu)  
$logo-align-mobile:           "center"; // "left"(default), "right" or "center" for mobile header
$nav-align:                   "right";  // "right"(default), "left" or "center" nav alignment 

// Burger options
$burger:                      none;     // with burger menu
$burger-right:                true;     // Put the burger menu on the right = true or left = false
$burger-lang:                 true;     // with language inside burger menu
$burger-active-width:         980px;    // burger menu activation screen width
$burger-active-width-up:      981px;    // burger menu activation screen width +1px for min-width media query
$burger-lang-texte:           true;     // true => burger lang switch with text  || false => burger lang switch with flags

// Footer options
$footer-dark:                 false;     // true => dark bg or false => light bg
$copyright:                   true;     // true => copyright display

// Tunnel options
$sticky-mobile-bottom-nav:    false;    // true => sticky buttom tunnel nav (prev, cancel, next buttons)

/* transition speed & effect */
// transition for all buttons ()
$default-transition:          all 0.3s ease-in-out;
$button-transition:           $default-transition;
$link-transition:             $default-transition;
$menu-transition:             $default-transition;
$input-transition:            $default-transition;

/* ==========================================================================
   $Default Fonts
   ========================================================================== */

/* self-hosted font integration */
/*
@font-face {
    font-family: "fontName";
    //modern font support
    src: url("fonts/fontName.otf") format("opentype");
    //old font support
    src: url("fonts/fontName.eot?#iefix");
    src: url("fonts/fontName.eot?#iefix") format("eot"),
         url("fonts/fontName.woff") format("woff"),
         url("fonts/fontName.ttf") format("truetype"),
         url("fonts/fontName.svg#defId") format("svg");
    font-weight: normal;
}
*/

/* google font integration */
@import 'https://fonts.googleapis.com/css?family=Open+Sans';

/* fonts variables */
$base-font: 'Open Sans', Arial, sans-serif;
$title-font: 'Open Sans', Arial, sans-serif;

// if no child-theme, import your webfonts here :
//@import 'https://fonts.googleapis.com/css?family=Lato';


/* ==========================================================================
   $Default Colors
   ========================================================================== */

/*
   Client color chart
   ========================================================================== */
$fake-color-1: #5B5A54;
$fake-color-2: #ff33cc;

/*
   Components colors
   ========================================================================== */
$dark-background: #fff;
$dark-border: #222;
$dark-product-separator: #222;
$dark-text-color: #222;
$dark-input-color: #fff;
$dark-popin-text: #fff;

/* Dark theme */
@if ($dark-theme == true) {
    $dark-background: #000;
    $dark-border: #333;
    $dark-product-separator: #666;
    $dark-text-color: #fff;
    $dark-input-color: #000;
    $dark-popin-text: #000;
}

/* Header */
$header-bg: transparent;
$loggedinfo-bg: #fff;
$loggedinfo-text: #222;
$header-text: #222;
$header-text-hover: #fff;
$header-text-bg-hover: #222;
$header-link: #222;
$loggedinfo-user: #222;
$language-text: #222;
$activelanguage-text: #222;
$language-bg: #fff;
$activelanguage-bg: #cccccc;
$language-hover:#cccccc;
$hoverlanguage-text: #222;
$hoverlanguage-text-mobile: #fff;
$language-hover-mobile: #222;

/* Burger */
$burger-bg:              $header-bg;
$burger-bar:             $header-text;
$burger-bar-bg:          $header-bg; 
$burger-bar-bg-close:  #c43f3f; 
$burger-text:            $header-text;
$burger-text-hover:      $header-text-hover;
$burger-text-hover-bg:   $header-text-bg-hover;
$burger-loggedinfo-bg:   $loggedinfo-bg;
$burger-loggedinfo-text: $loggedinfo-text;

@if ($header-dark == true) {
    /* Dark header */
    $header-bg: #222;
    $loggedinfo-bg: #222;
    $loggedinfo-text: #fff;
    $header-text: #fefefe;
    $header-text-bg-hover: #fff;
    $header-text-hover: #222;
    $header-link: #fefefe;
    $loggedinfo-user: #fefefe;
    $language-text: #222;
    $activelanguage-text: #222;
    $language-bg: #fff;
    $activelanguage-bg: #cccccc;
    $language-hover:#cccccc;
    $hoverlanguage-text: #222;
    $hoverlanguage-text-mobile: #222;
    $language-hover-mobile: #fff;
    
    /* Burger */
    $burger-bg:              $header-bg;
    $burger-bar:             $header-text;
    $burger-bar-bg:          $header-bg; 
    $burger-bar-bg-close:  #c43f3f; 
    $burger-text:            $header-text;
    $burger-text-hover:      $header-text-hover;
    $burger-text-hover-bg:   $header-text-bg-hover;
    $burger-loggedinfo-bg:   $loggedinfo-bg;
    $burger-loggedinfo-text: $loggedinfo-text;
}

/* Menu */
$menu-bg: #fff;
$menu-border: #5B5A54;
$menu-text: #5B5A54;
$menu-link: #5b5a54;
$menu-link-hover: #555;

/* Main */
$text: #253b56;
$highlight-text: red;
$main-bg: #f7f7f7;
$content-bg: #fff;

@if ($dark-theme == true) {
    /* Dark theme */
    $text: #fff;
    $main-bg: #000;
    $content-bg: #000;
}

/* Titles*/
$title: #253b56;
$h1: #253b56;
$h2: #253b56;
$h3: #253b56;
$h4: #253b56;
$h5: #253b56;
@if ($dark-theme == true) {
    $title: #fff;
    $h1: #fff;
    $h2: #fff;
    $h3: #fff;
    $h4: #fff;
    $h5: #fff;
}

/* Links */
$link: #5b5a54;
$link-hover: #b51f1f;

/* Forms */
$error-text: #ff0000;
$input-text: #555;

/* Native button */
$btn: #eacb5b;
$btn-text: #fff;
$btn-hover: #253b56;
$btn-text-hover: #fff;

/* Link button (no background) */
$btn-link-text: #5b5a54;
$btn-link-text-hover: #333;

/* Tunnel */
$tunnel-text: #ccc;
$tunnel-bg: #eee;
$tunnel-number: #ccc;
$tunnel-complete-bg: #000;
$tunnel-complete-text: #ccc;
$tunnel-active-bg: #000;
$tunnel-active-text: #5B5A54;
$tunnel-complete-number: #fff;
$tunnel-active-number: #fff;
$seances-bg: #ff7f00;
$seances-color : #fff;

/* Cart */
$cart-bg: #fff;
$cart-sum-bg: #3A3936;
$cart-sum-text: #ccc;

/* Calendar */
$day-bg: #efeee6;
$day-active-bg: #2f879b;
$day-labels-bg: #fff;
$calendar-header-bg: #fff;
$booking-header-text: #333;
$day-number-text: #fff;
$price-text: #fff;
$day-force-enabled-color: #eee;
$day-force-disabled-color: #ccc;

/* Flex calendar */
$month-selected-bg: #ff7f00;
$month-selected-text: #fff;
$day-selected-bg: #ff7f00;
$day-selected-text: #000;
$calendar-control: #565550;
$calendar-control-active: #2f879b;
$calendar-control-disabled: #ccc;

/* Sessions */
$session-selected-bg: #ff7f00;
$session-selected-text: #000;

/* Footer */
$footer-text: #000;
$footer-bg: #f7f7f7;
$copyright-bg: #222;
$footer-link: #000;
$footer-link-hover: #555;

@if ($footer-dark == true) {
    $footer-text: #fff;
    $footer-bg: #000;
    $footer-link: #fff;
    $footer-link-hover: #dbdad3;
}

/* Cookie footer banner */
$btn-cookie: $btn;
$btn-cookie-text: $btn-text;


/* ==========================================================================
   $Child Theme : override layout and colors for a specific theme
   ========================================================================== */

//@import "scss/child-themes/musee";



/* ==========================================================================
   $Layout
   ========================================================================== */
/**
 * @TODO :
 * Comments translation in english
 */

//Permet d'avoir les réseaux sociaux dans le footer avec un effet d'incrustation des icônes à l'intérieur du cercle
// @import "scss/child-themes/footer/footer-icon-circle";

//Permet de supprimer les icônes dans le footer.
// @import "scss/child-themes/footer/footer-icon-delete";

//Permet de mettre le texte en colonne à gauche des icônes des réseaux sociaux.
// @import "scss/child-themes/footer/footer-text-left-block";

//Permet de mettre le texte en colonne à droite des icônes des réseaux sociaux.
//@import "scss/child-themes/footer/footer-text-right-block";

//Permet de mettre le texte en ligne horizontale à droite des icônes des réseaux sociaux.
// @import "scss/child-themes/footer/footer-text-right-inline";

//Permet de positionner les blocs dans l'ordre (Logo - Icon - Texte).
// @import "scss/child-themes/footer/position-logo-icon-text";

//Permet de position les menus dans une barre et le logo au-dessous de la barre.
// @import "scss/child-themes/header/header-inline-border-background";

//Permet de centrer les blocs.
// @import "scss/child-themes/header/header-position-center";

// Navicon
//@import "scss/layouts/navicon";

// Footer menu block / inline
$footer-menu:  "inline";

// @import "scss/layouts/footer-menu-inline";


/* ==========================================================================
   $Effects
   ========================================================================== */

// button effect : fade, sweep-to-right, sweep-to-top, sweep-to-bottom, border, underline, grow-shadow
@import "scss/effects/fade";
// link effect : color, opacity, link-underline
@import "scss/effects/color";
// menu styling : menu (default), menu-custom
@import "scss/layouts/menu";


// variable.scss end