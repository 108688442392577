/* Masquage CSS des éléments traduits en dur dans l'HTML */
/* Solution de secours ! */

.lang-fr-FR .en-GB,
.lang-fr-FR .es-ES,
.lang-fr-FR .home-billet.en-GB.animate,
.lang-fr-FR .es-ES.animate,

.lang-en-GB .fr-FR,
.lang-en-GB .es-ES,
.lang-en-GB .home-billet.fr-FR.animate,
.lang-en-GB .home-billet.es-ES.animate,

.lang-es-ES .fr-FR,
.lang-es-ES .en-GB,
.lang-es-ES .home-billet.fr-FR.animate,
.lang-es-ES .home-billet.en-GB.animate
{
  display: none;
}