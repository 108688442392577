$button-effect: "fade";

/* Fade */
.btn, .btn.btn-primary {
  color: $btn-text;
  display: inline-block;
  vertical-align: middle;
  background-color: $btn;
  border-color: $btn;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.bottom .tunnel .btn-previous, .bottom .tunnel .btn-cancel {
  background-color: $btn;
}
.btn.btn-primary:hover, .btn:hover, .btn:focus, .btn:active {
  background-color: $btn-hover !important;
  color: $btn-text-hover;
}
