// Sessions hours border separation

.sessions-external .group-header {
    background: #f0f0f0;
}
.sessions-external .day-body {
    padding-top: 15px;
}
.sessions-external .session {
    flex-basis: 20%;
    margin: 0; 
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.1);
}

@media (min-width: 800px) {
    .actualYear,
    article.extension_Calendar[data-action="Default"] .day .dayDate,
    article.extension_Calendar[data-action="Calendar"] .day .dayDate,
    article.extension_Calendar[data-action="CalendarsAccordion"] .day .dayDate,
    article.extension_Calendar[data-action="Default"] .dayName,
    article.extension_Calendar[data-action="Calendar"] .dayName,
    article.extension_Calendar[data-action="CalendarsAccordion"] .dayName {
        font-size: 0.875rem;
    }
}

@media (min-width: 992px) {   
    .sessions-external .session {
        margin: -1px 0 0;
    }
    .sessions-external .group-header {
        padding: 30px 15px;
    }
    .sessions-external .day-body {
        padding-top: 0;
        padding-left: 10px;
    }
}




