/* =========================================================================== */
/* =======                                                             ======= */
/* =======             CSS spécifiques pour le client                  ======= */
/* =======                  Client specific CSS                        ======= */
/* =======                                                             ======= */
/* =========================================================================== */

/**
 * Table of Content

    $General layout
    $Header
    $Header mobile
    $Footer
    $Main content
    $Tunnel
    $Forms
    $Buttons
    $Errors
    $Responsive

 */



/* ==========================================================================
   $General layout
   ========================================================================== */

body {
}

/* row spacing */
.row {
    margin-top: 5px;
    margin-bottom: 5px;
}

//gestion des langues à la hussarde, désolé, pas le choix.
.lang-fr-FR .en,
.lang-en-EN .fr,
.lang-en-GB .fr {
    display: none;
}

/* ==========================================================================
   Tarteaucitron : alignement bottom du bandeau de consentement cookie
   Patch temporaire avant maj de l'admin par GeC
   ========================================================================== */

/* Position en pied de page du bandeau vs en haut */
.tarteaucitronAlertBigTop {
    top: auto !important;
    bottom: 0 !important;
}
/* Suppression du bouton de Gestion des services + nb de cookies*/    
#tarteaucitronAlertSmall {
    //display: none !important;
}
/* Suppression du bouton de nombre de cookies */
#tarteaucitronCookiesNumber {
    //display: none !important;                
}


/* ==========================================================================
   $Header
   ========================================================================== */

header {

    .languageModule li a {
        padding: 10px 0;

        img {
            margin-right: 0;
        }
    }

    .languageName {
        display: none;
    }
}

.siteLogo {
    display: none;
    // width: 250px;
    // height: 27px;
}


/* ==========================================================================
   $Header mobile
   ========================================================================== */

//If you increase the size of the bar think to adjust the width and height of the burger blocks
header .burger {
    // height: 50px;
    // width: 50px;
    .x,
    .y,
    .z{
        // height: 3px;
        // width: 26px;
    }
}


/* ==========================================================================
   $Footer
   ========================================================================== */

footer {
    border-top: 0;
    box-shadow: 0 -14px 20px 0 #0000001a;
}


/* ==========================================================================
   $Main content
   ========================================================================== */

.page-content {
    // background-image: url();
    #operator-name,
    .operatorname{
        display: none;
    }
}


/* ==========================================================================
   $Tunnel
   ========================================================================== */

// bug 10.7.L et 10.7.M : vue Beethoven
.cart {
    border: none;
}


/* ==========================================================================
   $Forms
   ========================================================================== */

.newUser label {
    font-size: 0.875rem;
    font-weight: 400;
}
.required .title {
    font-size: 0.875rem;
}


/* ==========================================================================
   $Buttons
   ========================================================================== */

.btn {
    //text-transform: uppercase;
}


/* ==========================================================================
   $Errors
   ========================================================================== */

.ticket-error.form-general-error {
    margin-top: 5px;
    position: relative;
}

.ticket-error {
    color: $error-text;
    border: 1px solid $error-text;
}

#error {
    color: $error-text;
    border: 1px solid $error-text;
}


/* ==========================================================================
   Responsive
   ========================================================================== */

#menuTrigger {
    color: $menu-link;
}

@media (max-width: $burger-active-width) {
}

@media (max-width: 992px) {
}

@media (max-width: $mbp) {
}

@media (min-width: 768px) {
}

/*@media (max-width: 1200px) {
.container {
max-width: 1000px;
}
}*/

@media (min-width: 980px) {
}


/* ==========================================================================
   Disney custom styles
   ========================================================================== */

.home-billet,
#accountMenu {
    display: none;
}


body {
    background: #fff url(img/background.jpg) no-repeat top center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}
header {
    min-height: 120px;
}
header .language-menu-wrapper {
    margin-top: 82px;
}

@media (max-width: 639px) {
    body {
        background: #fff url(img/background-175-825.jpg) no-repeat center top;
    }
    .help-panel {
        margin: 0.25rem 0 2rem;
        position: relative;
        width: 280px;
    }
    .help-panel:hover .contents {
        width: 55%;
    }
    #hotel-booking dd#booking-services {
        clear: both;
        margin: 0 0 10px;
        max-height: none;
        overflow: visible;
        white-space: wrap;
        width: 100%;
    }
    #home-fields-area label, 
    #details-preliminary-info dt, 
    #hotel-booking dt, 
    #details-fields-area dt, 
    #details-email dt, 
    .details dt {
        float: none;
    }
}

/* no tunnel */

.view:not(.bottom) .tunnel {
    display: none;
}


/* Espace de paiement groupes */
body {
    font-family: Arial, sans-serif;
}
.content h1,
.content h2 {
    color: #DFB113;
    font-size: 1.5rem;
}
.content h2 {
    padding-bottom: 0.2rem;
    font-size: 1.2rem;
    border-bottom: 1px solid #DFB113;
    margin: 2rem 0;
}


/* first form */
.content h2 i {
    margin-left: 0.1rem;
}
.content p {
    margin-bottom: 1rem;
}
.search-zone {
    margin-top: 2rem;
}
.content input[type="text"] {
    border: solid 1px #ccc;
}
.form-group label {
    width: 40%;
}
.form-group input[type="text"] {
    width: 50%;
}
.btn-wrap {
    text-align: right;
}
@media (max-width: 400px) {
    .form-group label,
    .form-group input[type="text"] {
        width: 100%;
    }
}
@media (min-width: 768px) {
    .form-group {
        display: flex;
    }
    .form-group label {
        flex-basis: 30%;
    }
    .form-group input[type="text"] {
        flex-basis: 50%;
    }
}

/* nav eGTS */
.egts-nav {
    display: none;
}

/* search results */
#delayed-payment-form > .row {
    margin-top: 1rem;
    font-size: 0.875rem;
}
#delayed-payment-form > .row i {
    font-size: 1.25rem;
}
#delayed-payment-form > .row > .row {
    margin-bottom: 0.5rem;
}

/* error - success color */

.search.delayed-payment .success {
    background-color: #fed6d6;
    border: 1px solid #f00;
    color: #f00;
}

/* confirmation */
.payment-summary form > div {
    font-size: 0.875rem;
    margin-bottom: 1rem;
}
.payment-summary form > div > input[type="checkbox"] + label {
    display: inline;
    margin-left: 0.25rem;
}

.search-zone .infobox{
    background-image: url('img/infos.png');
    background-repeat: no-repeat;
}

.MandatoryFields{
    text-align: right;
    margin-top: -35px;
    margin-right: -25px;
}

label#code-label::after,
label#contactcode-label::after,
label.information-label::after  {
    content: " *";
}

.infobox {
    margin-left: 10px;
    width: 17px;
    background-image: url('img/infos.png');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

span.product-label{
    display: block;
}

#modal_code .modal-dialog .modal-content,
#modal_codeuser .modal-dialog .modal-content,
#modal_date .modal-dialog .modal-content {
    width: 410px;
    margin: 0 auto;
}

.nav-wrapper .languageModule li {
    padding: 0 0.250rem !important;
}

#languageMenu li a.languageName{
    display: none;
}

.languageModule ul li a {
    width: 100%;
    line-height: 2;
}

.languageModule ul li:focus,
.languageModule ul li:hover {
    background: #eacb5b;
}

.languageModule ul li.active,
.languageModule ul li.active a{
    background-color: unset;
}
