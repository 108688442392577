/*=============================================================================================================================================*/
/*================                                                                                                             ================*/
/*================                                        CSS de base du site client.                                          ================*/
/*================              Ne pas toucher à ce fichier mais faire les surcharges dans le fichier master.css,              ================*/
/*================              situé dans ce même répertoire. Tout contrevenant sera immédiatement transformé en oeuf dur.    ================*/
/*================                                                                                                             ================*/
/*================                                       Basic CSS of the client site.                                         ================*/
/*================         Do not touch this file but overload it in the master.css file, located in the same directory.       ================*/
/*================                       Any offender will immediately be transformed into a hard-boiled egg.                  ================*/
/*================                                                                                                             ================*/
/*=============================================================================================================================================*/

/**
 * Table of Content

    $TOOLS
    $GENERAL LAYOUT
    $HEADER
    $BURGER MENU
    $MOBILES
    $FOOTER
    $INFO / SUCCESS / ERROR  
    $TABLE
    $BUTTON
    $FORM
    $LOGIN
    $TUNNEL
    $PRODUCT FAMILIES
    $SESSIONS (SEANCES)
    $TUNNEL STEPPER AND BOTTOM BUTTONS
    $TOOLTIP
    $CART / PANIER
    $IMAGE
    $CALENDAR / CALENDRIER
    $PAYMENT / PAIEMENT
    $ORDER
    $TEXT MODULE
    $MEMBERSHIPS / ADHESIONS
    $DARK THEME
    $RESPONSIVE RULES
    $MEMBERSHIPS / ADHESIONS RESPONSIVE
    $LOADING
    $EVOLUTIONS


 */

/* ==========================================================================
   $TOOLS
   ========================================================================== */

/* Layout tools */

/* floats */

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear,
.float-left + *:not(.float-left):not(.float-right),
.float-right + *:not(.float-left):not(.float-right) {
    clear: both;
    display: block;
}

/* use .mbp to stack under the Main Break Point */

@media (max-width: $mbp) {
    .float-left.mbp,
    .float-right.mbp {
        float: none;
    }
}

/* TODO: adding flex tools */

/* Hidden tools */
@media (max-width: 767px) {
    .desktop-only {
        display: none;
    }
}
@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */

.visuallyhidden {
    border: 0;
    clip: rect(0000);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    margin: -1px;
    padding: 0;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    width: auto;
    margin: 0;
}

/* Text cut tools */

/* Automatic text cut to prevent text superposition : http://rocssti.nicolas-hoffmann.net/ */

.cut {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
}

.nocut {
    word-wrap: normal;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    -o-hyphens: none;
    hyphens: none;
}

/* add ... if too long text-line, must specify a width to this container */

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

// Uppercase with CSS is allways better
.caps {
    text-transform: uppercase;
}

/* debug Safari */

/*fix safari bug with bootstrap grid*/

.row:before {
    display: inline;
}

/* ==========================================================================
   $GENERAL LAYOUT 
   ========================================================================== */

html {
    font-size: 1rem;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-size: 1rem;
    color: $text;
    background-color: $main-bg;
    font-family: $base-font;
}

.container.container-main {
    padding: 20px 40px;
}
@media (min-width: 768px) {
    .container.container-main {
        min-height: 500px;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

#content-inter {
    background-color: $content-bg;
    max-width: $container-width;
}
a {
    color: $link;
}

a:hover {
    color: $link-hover;
}

a:link,
a:visited,
a:hover,
a:active {
    outline: none;
}


/* keybord accessibility : keeps original focus outline */

a:focus,
button:focus {
    outline: thin dotted;
    outline-offset: 0;
}

/* links visual accessibility : internal p or li text links */

p p > .description li a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.product .title,
.family-title {
    color: $title;
    font-family: $title-font;
    font-weight: 300;
    line-height: none;
}

h1 {
    margin-bottom: 15px;
}

ul {
    margin-bottom: 0;
}

.nav > li {
    display: inline-block;
    list-style-position: inside;
    list-style-type: none;
}

#content {
    margin: 25px 0;
}

.bold {
    font-weight: bold;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    margin-left: 0;
}

/**
* 1. Avoid the IE 10-11 `min-height` bug.
* 2. Set `flex-shrink` to `0` to prevent some browsers from
*    letting these items shrink to smaller than their content's default
*    minimum size. See http://bit.ly/1Mn35US for details.
* 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
* 4. Issue on iOS 9, use the width media query !
*/

@media (min-width: 1025px) {
    .page-body {
        display: flex;
        flex-direction: column;
        height: 100vh;
        /* 1, 3 */
    }
    .page-header,
    .sticky-footer {
        flex: none;
        /* 2 */
    }
    .page-content {
        flex: 1 0 auto;
        /* 2 */
        padding: 0;
        width: 100%;
    }
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background: transparent;
}

/* ==========================================================================
   $HEADER
   ========================================================================== */

header {
    background: $header-bg;
    color: $header-text;
    position: relative;
    a,
    span,
    .fa {
        color: $header-text;
    }
}
// Manage hover and focus on header but not language module

header .nav-wrapper {
    > a,
    .nav li a,
    .dropdown a,
    .dropdown-menu a,
    #menu a {
        &:focus,
        &:hover {
            background: $header-text-bg-hover;
            color: $header-text-hover;
            span,
            .fa {
                color: $header-text-hover;
            }
        }
    }
    .dropdown.user .dropdown-menu .fa {
        display: none;
    }
    
}
.container-header {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: $container-header;
    // min-width: $min-container-header;
    padding-left: 0;
    padding-right: 0;
}
.nav-wrapper,
.logo-wrapper,
#accountMenu,
#logged-infos {
    display: inline-block;
}

.logo-wrapper {
    padding: 1rem 1rem;
}
.siteLogo {
    background-image: url($logo-client);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 27px;
    display: inline-block;
}
.menu-header {
    width: 100%;
}
header .nav-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    .nav.navbar-nav.navbar-right {
        float: initial !important;
        margin-right: 0;
    }
    .home-billet,
    .nav li a {
        padding: 2rem 15px;
        line-height: 1;
    }
    .dropdown {
        .username {
            vertical-align: bottom;
        }
        ul {
            margin: 0;
            padding: 0;
            border: none;
            border-radius: 0;
            background: $loggedinfo-bg;
            color: $loggedinfo-text;
            width: 100%;
        }
        li a {
            color: $loggedinfo-text;
            padding: 0.5rem 0 0.5rem 1rem;
            line-height: 1;
        }
        &.open .dropdown-menu > li > a {
            padding: 0.5rem 0 0.5rem 1rem;
            line-height: 1;
        }
    }
}
// manage hover and focus for moduleLanguage
@if ($nav-align == "left") {
    header .nav-wrapper {
        justify-content: flex-start;
    }

    @if ($logo-align == "center") {
        @media (min-width: $burger-active-width-up) {
            .menu-header {
                grid-row-start: 1;
            }
        }
    }
}
@if ($nav-align == "center") {
    header .nav-wrapper {
        justify-content: center;
    }
    header .nav-wrapper > *:first-child,
    header .nav-wrapper > *:nth-child(2)  {
        margin-left: auto;
    }
}

.home-billet span {
    display: none;
}

// home link language display
body:not(.lang-fr-FR) .home-billet.fr-FR,
body.lang-fr-FR .home-billet.lang-default {
    display: none;
}

.socialLogos {
    display: none;
}
@if ($header-icon == true) {
    .container-header {
        padding-bottom: 1rem;
    }
    .socialLogos {
        display: none;
    }
    @if ($header-icon == true) {
        .container-header {
            padding-bottom: 0rem;
        }
        .socialLogos {
            display: inline-block;
            position: absolute;
            bottom: 0;
            right: 0;
        }

        header .socialLogos {
            text-align: center;
            display: block;
            .fa {
                font-size: 1.5rem;
                padding: 0 0.5rem;
                margin: 0 1rem;
            }
        }
    }
}

@if ($text-accueil == true) {
    .home-billet .fa {
        display: none;
    }
    .home-billet span {
        display: inline-block;
    }
}

@if ($logo-align == "center") {
    .menu-header {
        width: auto;
    }
    .logo-wrapper {
        margin: 0 auto;
    }
    @media (max-width: $burger-active-width) {
        .menu-header {
            width: 100%;
        }

        header .nav-wrapper {
            display: flex;
            float: none;
        }
    }
    @media (min-width: $burger-active-width-up) {
        .container-header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .logo-wrapper {
            grid-column-start: 2;
        }
    }
}
@if ($logo-align-mobile == "center") {
    @media (max-width: $burger-active-width) {
        .container-header {
            transition: $default-transition;
        }
        .logo-wrapper {
            margin: 0 auto;
        }
    }
}

@if ($logo-align == "left") {
}
@if ($logo-align-mobile == "left") {
    @media (max-width: $burger-active-width) {
        .logo-wrapper {
            margin-left: 0;
            margin-right: auto;
        }
    }
}
@if ($logo-align == "right") {
    .container-header {
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
    }
    @media (min-width: $burger-active-width-up) {
        .menu-header {
            width: auto;
        }
        @if ($nav-align == "center") {
            .menu-header {
                flex-basis: 100%;
            }
        }
        @if ($nav-align == "right") {
            .menu-header {
                flex-basis: 100%;
            }
            .language-menu-wrapper {
                margin: 0;
            }
        }
    }
}
@if ($logo-align-mobile == "right") {
    @media (max-width: $burger-active-width) {
        .logo-wrapper {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

#showcase {
    min-height: 474px;
    background-size: cover;
    box-shadow: 0 20px 15px -20px #a2a2a2 inset;
}

/* Skip menu accessibility tool */

.skip-menu {
    max-width: 150px;
    position: relative;
    margin: 0;
}

.skip-menu a {
    left: -7000px;
    padding: 0;
    position: absolute;
    text-decoration: none;
    z-index: 1000;
    display: inline-block;
    background-color: $header-bg;
    color: $header-text;
}

.skip-menu a:focus {
    left: 0;
    position: relative;
}

/* Select2 background */

.formitem input,
.formitem textare .formitem select,
.formitem .select2-choice {
    background-color: #fff;
}



/* ==========================================================================
   $BURGER MENU
   ========================================================================== */

button.burger {
    display: none;
}
@media (max-width: $burger-active-width) {
    @if ($burger == true) {
        header a,
        header span,
        header .fa {
            color: $header-text;
        }
        header .nav-wrapper {
            > a,
            .nav li a,
            .dropdown a,
            .dropdown-menu a,
            #menu a,
            ul li a {
                &:focus,
                &:hover {
                    background: $burger-text-hover-bg;
                    color: $burger-text-hover;
                    span,
                    .fa {
                        color: $burger-text-hover;
                    }
                }
            }
        }
        header .burger {
            display: flex;
        }
        .container-header {
            flex-wrap: wrap;
            position: initial;
        }
        .page-content {
            margin-top: 4.5rem;
        }
        header {
            // position: fixed;
            width: 100%;
            top: 0;
            z-index: 9;
            background: $burger-bg;
        }
        .nav-wrapper {
            display: none;
            position: initial !important;
        }
        .home-billet {
            width: 100%;
            text-align: center;
            padding: 21px 15px;
        }
        .nav-wrapper {
            width: 100%;
            position: relative;
            z-index: 999999;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        #accountMenu,
        #logged-infos,
        .menu-header li {
            width: 100%;
        }
        #accountMenu .nav > li > a {
            text-align: center;
        }
        .nav > li > .dropdown-menu {
            position: relative !important;
            text-align: center;
            width: 100%;
            top: 0;
            background: $burger-loggedinfo-bg !important;
            border-top: 1px solid $burger-loggedinfo-text;
        }
        #logged-infos .nav > li > .dropdown-menu {
            border-top: 1px solid $burger-loggedinfo-text !important;
        }
        #menu {
            width: 100%;
        }
        
        .logo-lateral-space {
            width: 50px;
        }
    }

    @if ($burger-right == false) {

        .container-header {
            flex-direction: row;
        }
        button.burger {
            left: 0;
            right: auto;
        }
    }
    @if ($burger-right == true) {
        .container-header {
            flex-direction: row-reverse;
        }
        button.burger {
            right: 0;
            left: auto;
        }
    }
}

@media (min-width: $burger-active-width-up) {
    .logo-lateral-space {
        display: none;
    }
}

/* ==========================================================================
   $Mobiles
   ========================================================================== */

/*#menuTrigger {
color: $menu-link;
}*/

@media (max-width: $mbp) {
    // less spaces
    #content {
        margin: 0 0 15px 0;
    }
    .container.container-main {
        padding: 6px 10px;
    }
    .default .tunnel {
        margin-bottom: 5px;
    }
    .btn {
        line-height: 1.6;
    }
    /*formulaire d'inscription*/
    .newUser {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: solid 1px #333;
    }

    #menuTrigger {
        font-size: 1.75rem;
        line-height: 60px;
        width: 100%;
        text-align: center;
        i {
            margin-left: 5px;
        }
    }
    .container.container-navigation {
        padding-right: 0;
        padding-left: 0;
    }
    footer {
        padding: 0;
    }
    footer ul {
        width: 100%;
    }
    footer li {
        width: 100%;
        padding-left: 0;
    }
    footer .footer-navigation {
        text-align: center;
    }
    footer .social-logos {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .bottom .tunnel {
        margin-top: 40px;
    }
    .bottom .tunnel .row {
        margin-left: 0;
        margin-right: 0;
    }
    .view.vertical {
        padding-right: 16px;
        padding-left: 16px;
    }
    .tunnel .hidden-xs {
        //display: none !important;
    }
    .tunnel .hidden-xs.active {
        display: block !important;
    }
    .form-actions:after {
        content: " ";
        display: table;
        clear: both;
        margin-bottom: 50px;
    }
    .view.vertical {
        padding-left: 0;
        padding-right: 0;
    }
    .social-logos {
        margin-top: 1rem;
    }
}

/* end max-width: $mbp 767px; */



/* ==========================================================================
   $FOOTER
   ========================================================================== */

footer {
    .container-footer {
        align-items: center;
        flex-wrap: wrap;
    }
    padding: 40px;
    border-top: solid 1px $footer-text;
    border-bottom: solid 1px $footer-text;
    background-color: $footer-bg;
    ul.nav {
        margin-bottom: 0;
    }
    ul.nav a:focus,
    ul.nav a:hover {
        transition: $link-transition;
        text-decoration: none;
        opacity: 1;
        background-color: transparent;
    }
    li {
        line-height: 30px;
        width: 33%;
        margin-left: 0 !important;
        padding-left: 0px;
    }
    span,
    .fa {
        color: $footer-text;
    }
    .fa {
        font-size: 1.2rem;
        padding: 0 1rem;
    }
    a span {
        color: $footer-link;
    }
    a:hover span {
        opacity: 1;
        color: $footer-link-hover;
    }
}

@if ($footer-menu != "inline") {
    footer ul.nav li {
        display: block !important;
        width: 100%;
    }
    @media (min-width: $mbp) {
        footer ul.nav li {
            padding-left: 20px;
        }
    }
} @else {
    footer li {
        width: auto;
    }
}

@media (min-width: $mbp-up) {
    .container-footer {
        display: flex;
        justify-content: space-between;
    }
    footer .footer-navigation {
        flex: 1 0 auto;
    }
    footer .social-logos {
        text-align: right;
        display: inline-block;
    }
}

/* ==========================================================================
   $INFO / SUCCESS / ERROR  
   ========================================================================== */

.ticket-info {
    color: #635c56;
    border: 1px solid #d3d871;
    background-color: #f8f5d2;
}

.ticket-success {
    color: #635c56;
    border: 1px solid #eaeed7;
    background-color: #e5efcc;
}

.ticket-error {
    background-color: #fed6d6;
}

.ticket-success,
.ticket-info,
.ticket-error {
    font-size: 0.813rem;
    float: left;
    margin: 15px 0;
    padding: 7px;
}

.ticket-success span,
.ticket-info span,
.ticket-error span {
    font-weight: bold;
}

#error {
    margin: 20px 20px 30px 20px;
    padding: 30px;
    background-color: #fbe5e5;
}

.error.ticket-warning.small {
    color: red;
    font-size: 1.2rem;
}

/* ==========================================================================
   $TABLE
   ========================================================================== */

.table-holder {
    overflow: auto;
    .table .align-left {
        text-align: left;
    }
    .table .align-right {
        text-align: right;
    }
    .table .align-center {
        text-align: center;
    }
    table {
        width: 100%;
    }
    table.table {
        margin-bottom: 0 !important;
    }
    .table-holder,
    table td {
        vertical-align: middle;
        color: #444;
    }
    table td,
    table th {
        padding: 8px;
        empty-cells: show;
    }
    table th {
        font-size: 0.813rem;
        padding: 8px;
        vertical-align: middle;
        color: #4d4d4d;
    }
    table thead tr:first-child {
        background: linear-gradient(to bottom, #fff 0%, #f1f1f1 100%) repeat
            scroll 0 0 rgba(0, 0, 0, 0);
    }
    table th.sorting,
    table th.sorting_asc,
    table th.sorting_desc {
        padding: 8px 19px 8px 8px;
    }
    .form-buttons {
        margin-top: 30px;
    }
    table th .sort i {
        line-height: 20px;
        float: right;
    }
    table th .sort:hover,
    table th .sort:focus {
        text-decoration: none;
    }
    table .th-checkbox {
        width: 0;
    }
    table td label.checkbox {
        width: 100%;
    }
    table td a {
        display: block;
        width: 100%;
    }
    table td a:hover,
    table td a:focus {
        text-decoration: none;
    }
    table.clickable td {
        cursor: pointer;
    }
    table.clickable tr.active td,
    table.clickable tr.active td:hover {
        color: #fff;
        background-color: #62737b !important;
    }
    table td.details-holder,
    table th.details-holder {
        display: none;
    }
    th.large {
        min-width: 250px;
        max-width: 250px;
    }
    th.medium {
        min-width: 100px;
        max-width: 100px;
    }
    th.small {
        min-width: 50px;
        max-width: 50px;
    }
    .dataTable .details {
        padding: 0;
        background-color: #fff;
    }
    div.innerDetails {
        display: none;
        padding: 10px;
        background-color: #fff;
    }
    .dataTable .details tr td {
        padding: 20px 20px 20px 25px;
        color: #777;
        border-left: 20px solid #f1f1f1;
        background-color: #fff;
    }
    table thead input[type="text"] {
        display: block;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        min-width: 200px;
        min-height: 28px;
        margin: 0;
    }
    table thead input.search_init {
        min-width: 0;
    }
    table thead td {
        background-color: #fff;
    }
    .show-details i {
        color: #aaa;
    }
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
    background-color: #f5f5f5;
}

table.table {
    margin: 0;
}

.dataTables_filter {
    display: none;
}

.table-striped tbody > tr:nth-child(2n + 1) > td,
.table-striped tbody > tr:nth-child(2n + 1) > th {
    background-color: #fcfcfc;
}

/* ==========================================================================
   $BUTTON
   ========================================================================== */

.btn {
    border-radius: 10px;
    transition: $button-transition;
}

/* ==========================================================================
   $FORM
   ========================================================================== */

.formitem label,
.extension_Form .top .formitem label {
    margin-top: 1rem;
    margin-bottom: 0.1rem;
}

.formitem .required {
    font-size: 1rem;
    vertical-align: inherit;
}

label.m-wrap,
input.m-wrap,
button.m-wrap,
select.m-wrap,
textarea.m-wrap {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 20px;
}

input.m-wrap,
button.m-wrap,
select.m-wrap,
textarea.m-wrap {
    font-family: "Segoe UI", "Helvetica Neue", Helvetic Arial, sans-serif;
}

label.m-wrap {
    display: block;
    margin-bottom: 5px;
}

.extension_Form .left label,
.extension_Form .left input,
.extension_Form .left .multi-date,
.left .control .select2-container.full {
    margin: 0;
}

.extension_Form .left .multi-date div.select2-container {
    width: 18% !important;
}

textare input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    font-size: 0.875rem;
    font-weight: normal;
    vertical-align: top;
    color: #333;
    outline: 0 none;
    filter: none;
    line-height: 35px;
    padding: 0 5px !important;
    transition: $input-transition;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: none;
    color: $input-text;
}

input.m-wrap {
    border: 1px solid #e5e5e5;
}

.toolbox-content input.m-wrap {
    color: #a0a0a0;
    border: none;
}

textarea.m-wrap {
    height: auto;
}

.uneditable-input.focus,
textarea.m-wrap:focus,
select.m-wrap:focus,
input.m-wrap[type="text"]:focus,
input.m-wrap[type="password"]:focus,
input.m-wrap[type="datetime"]:focus,
input.m-wrap[type="datetime-local"]:focus,
input.m-wrap[type="date"]:focus,
input.m-wrap[type="month"]:focus,
input.m-wrap[type="time"]:focus,
input.m-wrap[type="week"]:focus,
input.m-wrap[type="number"]:focus,
input.m-wrap[type="email"]:focus,
input.m-wrap[type="url"]:focus,
input.m-wrap[type="search"]:focus,
input.m-wrap[type="tel"]:focus,
input.m-wrap[type="color"]:focus,
.m-uneditable-input:focus {
    border-color: #999;
    outline: 0 none;
    box-shadow: none;
}

input[disabled],
select[disabled],
textarea[disabled] {
    cursor: not-allowed;
    background-color: #f4f4f4;
}

input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;
    background-color: #f9f9f9;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
    background-color: rgba(0, 0, 0, 0);
}

input.m-wrap[type="radio"],
input.m-wrap[type="checkbox"] {
    line-height: normal;
    -moz-box-sizing: border-box;
    margin: 4px 0 0;
    cursor: pointer;
}

select.m-wrap,
input.m-wrap[type="file"] {
    line-height: 30px;
    min-height: 34px;
}

select.m-wrap {
    width: 220px;
    border: 1px solid #e5e5e5;
    outline: medium none;
    background-color: #fff;
    background-image: none;
    filter: none;
}

select.m-wrap[multiple],
select.m-wrap[size] {
    height: auto;
}

select.m-wrap:focus,
input.m-wrap[type="file"]:focus,
input.m-wrap[type="radio"]:focus,
input.m-wrap[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline-offset: -2px;
    box-shadow: none;
}

input.m-wrap[class*="span"] {
    float: none;
    height: 34px;
    margin-left: 0;
}

select.m-wrap[class*="span"],
textarea.m-wrap[class*="span"] {
    float: none;
    margin-left: 0;
}

.input-prepend,
.input-append {
    display: inline-block;
    border: 0 none;
}

.input-append .add-on,
.input-prepend .add-on {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 24px;
    display: inline-block;
    width: auto;
    min-width: 16px;
    min-height: 24px;
    padding: 4px 5px;
    text-align: center;
    border: 1px solid #e5e5e5;
    background-color: #e5e5e5;
    text-shadow: 0 1px 0 #fff;
}

.input-append .add-on > .halflings-icon,
.input-prepend .add-on > i,
.input-prepend .add-on > [class^="icon-"] {
    margin-top: 5px;
    margin-left: 3px;
}

.input-append .add-on > .halflings-icon .input-append .add-on > i,
.input-append .add-on > [class^="icon-"] {
    margin-top: 5px;
    margin-left: 0;
}

.input-append .m-wrap,
.input-append .m-wrap:focus {
    margin-right: 1px;
}

.input-prepend input,
.input-prepend input:focus {
    margin-left: 1px;
    border-left: 0 none;
}

.input-append input[class*="span"],
.input-prepend input[class*="span"] {
    display: inline-block;
}

.m-wrap.xsmall {
    width: 50px;
}

.m-wrap.small {
    width: 120px;
}

.m-wrap.medium {
    width: 206px;
}

.m-wrap.large {
    width: 320px;
}

.m-wrap.huge {
    font-size: 1.5rem;
    line-height: 36px;
    width: 480px;
    min-height: 36px;
    padding: 22px 8px;
}

select.m-wrap.xsmall {
    width: 64px;
}

select.m-wrap.small {
    width: 134px;
}

select.m-wrap.medium {
    width: 220px;
}

select.m-wrap.large {
    width: 334px;
}

input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
    border-color: #666;
    box-shadow: none;
}

.select2-container .select2-choice span {
    padding: 0 10px 0 5px;
}

.login-form label,
.forget-form label {
    color: $text;
}

.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    color: $input-text;
}

.formitem label {
    font-family: "Open Sans", Arial, sans-serif !important;
}

.form-vertical.login-form {
    margin-bottom: 3rem;
}

/* form connexion : mise en page en 2 col-md-6 */
@media (min-width: 562px) {
    .col-md-6.extension_Form .formitem label + p.required {
        width: 50%;
        display: inline-block;
        color: #333;
    }
    .col-md-6.extension_Form .left .formitem label + p.required + input {
        margin-left: 168px;
    }
    .col-md-6.extension_Form .btn.btn-save {
        margin-right: 5rem;
    }
}
@media (min-width: 992px) {
    .col-md-6.extension_Form .left .multi-date {
        display: inline-block !important;
        width: auto !important;
    }
}
.extension_Form legend,
.extension_Form lengend,
.newUser legend,
.newUser lengend {
    border-bottom: none;
    margin-top: 2rem;
    display: block;
}

.formitem legend,
.formitem lengend {
    display: block;
    margin-top: 2.5rem;
}

.extension_Login .fa {
    display: none;
}

.extension_Login .dropdown.user .fa {
    display: inline-block;
}
/* ==========================================================================
   $LOGIN
   ========================================================================== */

.login-form #avatar,
.login-form img,
.login-form .input-icon i.icon-user,
.login-form .input-icon i.icon-lock,
.forget-form .input-icon i.icon-envelope {
    display: none;
}

.login-form label,
.forget-form label {
    font-family: $base-font;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 20px;
    display: block;
    margin-bottom: 5px;
}

.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    font-family: $base-font;
    font-size: 0.813rem;
    line-height: 35px;
    width: 100%;
    min-height: 35px;
    padding: 0 5px;
    border: 1px solid #d0d0d0;
    box-shadow: none;
}

.login-form .btn.btn-save:after,
.forget-form .btn.btn-delete:after,
.forget-form .btn.btn-save:after {
    clear: both;
}

.login-form,
.forget-form {
    margin: 0;
    padding: 0;
    border: medium none;
    background-color: transparent;
}

.login-form .form-actions,
.forget-form .form-actions {
    clear: both;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
}

#back-btn.pull-right {
    float: left !important;
    margin: 30px 0 !important;
}

.forget-form {
    display: none;
}

.login-form .checkbox {
    padding-left: 0;
}

.extension_Login div.checker {
    margin-top: 0;
}

.extension_Login .remember {
    float: left;
}

.extension_Login .forget-password {
    line-height: 20px;
    float: right;
}

.extension_Login .forget-password a {
    font-size: 0.875rem;
    color: #3a3936;
}

.extension_Login .forget-password a:hover {
    opacity: 0.8;
}

.extension_Login .btn-save {
    margin-top: 30px !important;
}

/* ==========================================================================
   $TUNNEL
   ========================================================================== */

.default .tunnel {
    padding: 10px;
}

.hidden-xs.step.active {
    display: block !important;
}

.default .tunnel .step {
    font-family: Helvetic Arial, sans-serif;
}

.stickyRow {
    position: -webkit-sticky;
    position: sticky;
    bottom: 1px;
    pointer-events: none;
    z-index: 100;
}

.stickyTunnel {
    pointer-events: all;
    /*box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, .30);*/
    background-color: #fff;
}

.modal-open .modal-backdrop {
    display: none;
}

.stickyRow .modal {
    pointer-events: all;
}


@media (max-width: $mbp) {
    @if ($sticky-mobile-bottom-nav == false) {
        // non sticky tunnel buttons
        .stickyRow {
            position: relative;
        }
    }
}

/* ==========================================================================
   $PRODUCT FAMILIES
   ========================================================================== */

.family-title {
    font-family: $title-font;
    margin-left: 15px;
    margin-right: 15px;
}

.familyModule h2 {
    font-size: 1.876rem;
    margin-top: 0;
    margin-bottom: 40px;
}

.familyModule .product img {
    float: none;
}

.familyModule .product .title {
    line-height: normal;
    margin: 40px 0 10px 0;
}

.familyModule .product .title span {
    font-family: "Open Sans", Arial, Helvetic sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
}

.familyModule .product .description {
    font-size: 0.875rem;
    min-height: 70px;
}

.familyModule .product .description p {
    white-space: normal;
}

.familyModule .product.no-border {
    margin-top: 0;
    padding: 20px;
    border-top: none;
}

.familyModule .product.no-border:first-child {
    margin-top: 0;
}

.extension_Familly {
    padding: 0 !important;
}

@media (min-width: $mbp-up) {
    .extension_Familly > div > p {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.extension_Familly form {
    margin: 0;
}

.extension_Text h2 {
    margin: 0;
}

.familyModule .product {
    float: left;
    width: 50%;
}

.extension_Products .product {
    margin: 0 0 30px;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: none;
}

.extension_Products .family {
    margin-bottom: 1.5rem;
}

.families .extension_Products .product {
    margin: 0 0 40px;
}

.extension_Products .families h2 {
    display: none;
}

.extension_Products .product .title {
    font-family: $base-font;
    font-size: 1.25rem;
    font-weight: normal;
    color: $title;
}

.extension_Products .product.odd,
.product.even {
    background-color: #fff;
}

.extension_Products .product img {
    float: none;
}

.extension_Products .vertical .product img {
    width: 100%;
    margin: 0 0 40px 0;
    border: none;
}

.extension_Products .vertical .header-price,
.extension_Products .vertical .header-quantity {
    color: #3a3936;
}

.products .short > br:first-child,
.products .short > p + br {
    display: none;
}

.default .products .row .product.col-md-6 .btn {
    font-size: 1em;
    color: $btn-text;
    height: auto;
    border-radius: 0 !important;
}

.default .products .row .product.col-md-6 .infos-container {
    min-height: 100px;
}

@media (min-width: $mbp-up) {
    .products .product .description {
        margin-bottom: 40px;
    }
    .products .product .description + .btn {
        right: 15px;
    }
}

.product .product-title {
    text-transform: uppercase;
    color: $title;
}

.vignette .product {
    padding: 0 !important;
    margin: 1rem;
}
.extension_Familly .view.vignette .product-title {
    width: 100% !important;
    bottom: 0;
    top: initial;
}

.extension_Familly .view.vignette .overlay {
    width: 100% !important;
    height: 100%;
}
.vignette .product.hover .product-title,
.vignette .product.mobile .product-title {
    bottom: 50% !important;
}

.extension_Familly .view.vignette .product .btn.btn-primary {
    height: 100%;
    background-color: transparent !important;
    width: 100% !important;
    padding: 0 !important;
    left: 0;
}

.product img {
    max-width: 100%;
}

// correction du bug de tooltip qui passe sous le fond tournant du bloc .produit
.families .product {
    overflow: visible;
}

.element-text p:last-child {
    margin-bottom: 15px;
}

/* Récap commande + gros */

.col-md-12.extension_Cart .cart-summary {
    font-size: 0.875rem !important;
}

/* commandes et retour de paiement : debug double scroll horizontal en mobile */
@media (max-width: 768px) {
    .container.container-main > .component > .content > .row {
        margin-left: 0;
        margin-right: 0;
    }
}

/* prix sur une seule ligne */
.products .productLine span[itemprop="price"] {
    white-space: nowrap;
}
@media (max-width: 479px) {
    .extension_Products .product {
        padding: 15px 10px 0;
    }
}

/* Correction quali : clearfix sur productline */
.productLine:after {
    content: "";
    display: block;
    clear: both;
}

/* ==========================================================================
   $SESSIONS (SEANCES)
   ========================================================================== */

article[data-action="Default"].extension_Calendar #calendar,
article[data-action="CalendarsWeek"].extension_Calendar #calendar,
article[data-action="Calendar"].extension_Calendar #calendar,
article[data-action="CalendarsAccordion"].extension_Calendar #calendar,
article[data-action="Default"].extension_Calendar .calendar,
article[data-action="CalendarsWeek"].extension_Calendar .calendar,
article[data-action="Calendar"].extension_Calendar .calendar,
article[data-action="CalendarsAccordion"].extension_Calendar .calendar {
    background: #f0f0f0;
}

article[data-action="Default"].extension_Calendar .disabled,
article[data-action="CalendarsWeek"].extension_Calendar .disabled,
article[data-action="Calendar"].extension_Calendar .disabled,
article[data-action="CalendarsAccordion"].extension_Calendar .disabled {
    background: #ccc;
}

article[data-action="SessionsGrid"].extension_Calendar
    .session.selected
    .sessionWrapper {
    background-color: $seances-bg;
    border-color: $seances-bg;
    color: $seances-color;
}

/* selection de séance */

article.extension_Calendar[data-action="Sessions"] .session.selected {
    background-color: $session-selected-bg !important;
    border-color: $session-selected-bg !important;
    color: $session-selected-text;
}

.calendarHeader {
    padding-top: 1rem;
}

.carousel-control {
    font-size: 1.25rem;
    color: $calendar-control;
    text-shadow: none;
    background-color: transparent;
    background-image: none !important;
    filter: alpha(opacity=1);
    opacity: 1;
}

#monthsCarousel .item button.active,
#monthsCarousel .item button:focus:not(.active),
#monthsCarousel .item button:hover:not(.active),
.monthsCarousel .item button.active,
.monthsCarousel .item button:focus:not(.active),
.monthsCarousel .item button:hover:not(.active) {
    background-color: $month-selected-bg;
    color: $month-selected-text;
}

.carousel-control:focus,
.carousel-control:hover,
.yearSwitcher .previous:focus:not(.disabled),
.yearSwitcher .next:focus:not(.disabled),
.yearSwitcher .previous:hover:not(.disabled),
.yearSwitcher .next:hover:not(.disabled) {
    color: $calendar-control-active;
    cursor: pointer;
}

article.extension_Calendar[data-action="Default"] .disabled,
article.extension_Calendar[data-action="Calendar"] .disabled,
article.extension_Calendar[data-action="CalendarsAccordion"] .disabled {
    background-color: transparent !important;
    color: $calendar-control-disabled;
}

.day.before {
    border-color: #ccc;
    color: transparent !important;
    opacity: 0.7;
}
@media (min-width: 992px) {
    div[data-sessions-container="external"] ~ form .calendar,
    div[data-sessions-container="external"] ~ form #calendar {
        float: left;
        width: 50% !important;
    }
}
@media (min-width: 800px) {
    .actualYear,
    article.extension_Calendar[data-action="Default"] .day .dayDate,
    article.extension_Calendar[data-action="Calendar"] .day .dayDate,
    article.extension_Calendar[data-action="CalendarsAccordion"] .day .dayDate,
    article.extension_Calendar[data-action="Default"] .dayName,
    article.extension_Calendar[data-action="Calendar"] .dayName,
    article.extension_Calendar[data-action="CalendarsAccordion"] .dayName {
        font-size: 0.875rem;
    }
}

/* ==========================================================================
   $TUNNEL STEPPER AND BOTTOM BUTTONS
   ========================================================================== */

// surcharge du CSS du module tunnel qui enlève le background des boutons sans checker si la couleur ne fait pas du blanc sur blanc
.bottom .tunnel .btn-previous:not(:hover):not(:focus),
.bottom .tunnel .btn-cancel:not(:hover):not(:focus) {
    background: unset;
    color: #666;
}

.extension_Tunnel .step.complete .number {
    padding-right: 1px;
    padding-left: 0;
}

/* IE 11 patch */

.bottom .tunnel .previous {
    flex: auto;
}

.default .tunnel .step .number {
    color: $tunnel-number;
    border-color: $tunnel-text;
    background: $tunnel-bg;
    line-height: 22px;
}

.default .tunnel .step .text {
    color: $tunnel-text;
}

.default .tunnel .step.complete .number {
    color: $tunnel-complete-number;
    border-color: $tunnel-complete-bg;
    background: $tunnel-complete-bg;
    line-height: 33px;
}

.default .tunnel .step.complete .text {
    color: $tunnel-complete-text;
}

.default .tunnel .step.active .number {
    color: $tunnel-active-number;
    border-color: $tunnel-active-bg;
    background: $tunnel-active-bg;
}

.default .tunnel .step.active .text {
    color: $tunnel-active-text;
}

/* TODO: table de la vue récap à cibler autrement que par une classe Bootstrap ! */

.extension_Cart.col-md-12 table {
    font-size: 0.875rem;
}

/* ==========================================================================
   $TOOLTIP
   ========================================================================== */

i.fa[data-toggle="tooltip"] {
    cursor: help;
}

.tooltip-inner {
    padding: 0.5rem 0.75rem;
    text-align: left;
    white-space: pre-wrap;
    font-size: 0.75rem;
}

/* ==========================================================================
   $CART / PANIER
   ========================================================================== */

.extension_Cart .cart {
    box-shadow: none;
}

.extension_Cart .cart-summary {
    box-shadow: none;
}

.extension_Cart #show-cart {
    display: none;
}

.extension_Cart {
    padding-top: 0;
}

.extension_Cart .cart-summary {
    margin: 0;
    padding: 0;
    border: none;
}

.extension_Cart .cgv a {
    margin-left: 25px;
}

.extension_Cart .cart-summary .products {
    margin: 0 0 30px;
}

.extension_Cart .cart-summary h3 {
    display: none;
}

.extension_Cart .cart-summary .products tr.odd,
.extension_Cart .cart-summary .products tr.even {
    /*background-color: $cart-bg;*/
    border: 1px solid #d0d0d0;
}

.extension_Cart .cart-summary .products thead tr {
    border: 1px solid #d0d0d0;
}

.extension_Cart hr {
    display: none;
}

.extension_Cart .cart-summary .products thead th {
    line-height: 30px !important;
    height: 30px;
    padding: 10px;
}

.cart-summary .ticket-info,
.cart-summary table {
    width: 100%;
}

/* OPTION BOUTON AJOUT AU PANIER AJAX : bouton et bulle d'ajout au panier */

.addButton.col-xs-12.col-sm-12,
.addButton.col-xs-12.col-md-12 {
    padding-left: 0;
    padding-right: 0;
}

.addCartInformation {
    margin-bottom: 20px;
}

@media (min-width: $mbp-up) {
    .addButton {
        text-align: right;
        margin-bottom: 20px !important;
    }
    .addButton:after {
        content: "";
        display: block;
    }
    .addButton span {
        display: inline-block;
        margin-bottom: 20px;
    }
    .addCartInformation {
        clear: both;
    }
}

/* ajout d'une marge sur le bouton d'ajout au panier */

.addButton:before {
    content: " ";
    display: block;
    height: 10px;
}

/* bouton ajout panier suite : pour corriger le float non clearé */

.extension_Products .product:after {
    clear: both;
    content: " ";
    display: block;
}

.extension_Cart .cart-summary .products tr.odd,
.extension_Cart .cart-summary .products tr.even {
    background-color: $cart-bg;
}

.cart-summary .ticket-info {
    color: $cart-sum-text;
    border: $cart-sum-bg;
    background: $cart-sum-bg;
}

/* debug bouton ajout au panier au dessus du tunnel sticky */
.extension_Products .view.parent .product .addButton {
    z-index: unset;
}

/* ==========================================================================
   $IMAGE
   ========================================================================== */

.extension_Image img {
    width: 100%;
}

/* ==========================================================================
   $CALENDAR / CALENDRIER
   ========================================================================== */

.extension_Booking .booking .content .day {
    border: none;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}

.extension_Booking .booking .content .week {
    border-right: none;
    border-left: none;
}

.extension_Booking .booking .day-labels {
    margin-bottom: 0;
}

.extension_Booking .booking {
    padding: 0;
    box-shadow: none;
}

.extension_Booking .content {
    margin-right: 1px;
    margin-bottom: 1px;
}

.extension_Booking .booking .day-background {
    background-color: $day-bg;
}

.extension_Booking .booking .force-enabled-color {
    background-color: $day-force-enabled-color;
}

.extension_Booking .booking .force-disabled-color {
    background-color: $day-force-disabled-color;
}

.extension_Booking .booking .day.active .day-background,
.extension_Booking .booking .day.enabled.active .day-background {
    border-color: $day-active-bg;
    background-color: $day-active-bg;
}

.extension_Booking .booking .day-labels {
    border-bottom: 1px solid $day-labels-bg;
    background-color: $day-labels-bg;
}

.booking .header *,
.booking .header a {
    color: $booking-header-text;
}

.booking .content .day.active span {
    color: $day-number-text;
}

.booking .content .day.active .price {
    color: $price-text;
}

/**
* calendrier flex, CSS neutre d'upgrade
* permet d'obtenir les styles neutres année et mois
* surbrillance mois en cours
*/

.carousel-control {
    font-size: 20px;
    color: #565550;
    text-shadow: none;
    background-color: transparent;
    background-image: none !important;
    filter: alpha(opacity=1);
    opacity: 1;
}
article[data-action="Default"].extension_Calendar
    .yearSwitcher
    .previous.disabled,
article[data-action="Default"].extension_Calendar .yearSwitcher .next.disabled {
    background-color: transparent;
}
.carousel-control:focus,
.carousel-control:hover,
.yearSwitcher .previous:focus:not(.disabled),
.yearSwitcher .next:focus:not(.disabled),
.yearSwitcher .previous:hover:not(.disabled),
.yearSwitcher .next:hover:not(.disabled) {
    color: black;
    cursor: pointer;
}

.monthsCarousel .item button,
.monthsCarousel .item button.active {
    border: none;
}

@media (min-width: 982px) and (max-width: 1200px) {
    .dayName .long {
        font-size: 0.875em;
    }
}

/* jours de la semaine plus petits */
@media (min-width: 992px) and (max-width: 1199px) {
    .calendar .dayName .long {
        font-size: 0.75rem;
    }
}

article.extension_Calendar[data-action="Default"] .selected,
article.extension_Calendar[data-action="Calendar"] .selected,
article.extension_Calendar[data-action="CalendarsAccordion"] .selected {
    background: $day-selected-bg !important;
    color: $day-selected-text;
}

@media (min-width: 982px) and (max-width: 1200px) {
    .dayName .long {
        font-size: 0.875em;
    }
}

@media (max-width: 359px) {
    .monthChooser {
        font-size: 0.75rem;
        padding: 4px;
    }
    .dayName {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

/* ==========================================================================
   $PAYMENT / PAIEMENT
   ========================================================================== */

.extension_Payment i {
    font-size: 80px;
}

.extension_Payment .content {
    padding-left: 120px;
}

.extension_Payment .content p {
    margin: 0 0 50px 0;
}


/* ==========================================================================
   $TEXT MODULE
   ========================================================================== */

.extension_Text p {
    font-family: $base-font;
    font-size: 0.875rem;
    margin: 0;
}

/* ==========================================================================
   $MEMBERSHIPS / ADHESIONS
   ========================================================================== */

.extension_Participan {
    .header,
    .line {
        display: flex;
        justify-content: flex-end;
        margin: 0 5px;
    }
    .header {
        font-weight: bold;
        padding-bottom: 5px;
    }
    .family-name {
        flex: 1;
    }
    .header-unit-price,
    .header-quantity,
    .header-total {
        /*         margin-left: 25px; */
        width: 10%;
        text-align: right;
    }
    .unit-price,
    .quantity,
    .total {
        width: 10%;
        text-align: right;
    }
    .line {
        display: flex;
        border-top: solid 1px #ddd;
        border-bottom: solid 1px #ddd;
        align-items: center;
    }
    .name,
    .date,
    .unit-price,
    .quantity,
    .total,
    .pic {
        align-items: stretch;
        height: 100%;
        padding: 10px 0;
    }
    .pic {
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        font-size: 1.5em;
    }
    .pic,
    .name {
        padding-left: 15px;
        padding-right: 15px;
    }
    .date {
        flex: 1;
        text-align: center;
    }
    .users {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .user {
        margin: 5px;
        padding: 10px;
        background: #dddddd;
        max-width: 19%;
    }
    .avatar {
        img {
            max-width: 100%;
        }
    }
    .user-name input {
        width: 100%;
        margin-bottom: 10px;
    }
}

#ModalPhoto .modal-content {
    min-height: 0;
}

.list-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
        max-width: 10%;
        margin: 1px;
        max-height: 100px;
    }
}

/* ==========================================================================
   $DARK THEME
   ========================================================================== */

@if ($dark-theme) {
    .default .tunnel,
    .extension_Products .product.odd,
    .product.even,
    article[data-action="Default"].extension_Calendar #calendar,
    article[data-action="Calendar"].extension_Calendar #calendar,
    .cart-summary .roomList li .moreInfos,
    .cart-summary,
    .extension_Cart .cart-summary .products tr.odd,
    .extension_Cart .cart-summary .products tr.even,
    .table-striped tbody > tr:nth-child(2n + 1) > td,
    .table-striped tbody > tr:nth-child(2n + 1) > th {
        background: $dark-background;
        border: solid 1px $dark-border;
    }

    .extension_Products .productLine {
        background: $dark-background !important;
        border-bottom: solid 1px $dark-product-separator;
    }

    .product select {
        background: $dark-background;
    }

    article[data-action="SessionsGrid"].extension_Calendar
        .session
        .sessionWrapper
        .remaining,
    article[data-action="SessionsGrid"].extension_Calendar
        .session
        .sessionWrapper
        .hour,
    .cart-summary *,
    .table-striped tbody > tr:nth-child(2n + 1) > td,
    .table-striped tbody > tr:nth-child(2n + 1) > th,
    .table-holder table thead tr th,
    legend,
    .formitem label {
        color: $dark-text-color;
    }

    .table-holder table thead tr:first-child {
        background: #222;
        color: $dark-text-color;
    }

    .extension_Products .vertical .header-price,
    .extension_Products .vertical .header-quantity {
        color: $dark-text-color;
    }
    .formitem .error,
    .extension_Login .forget-password lengend span,
    legend span {
        color: $dark-text-color !important;
    }
    input,
    select,
    textarea {
        color: $dark-input-color;
    }
    body .modal-body {
        color: $dark-popin-text !important;
    }

    .extension_Login .forget-password a {
        color: #fff;
    }
    .product .product-title {
        color: $title;
    }
    .extension_Products .product.noImg .title {
        background: transparent;
    }
    .extension_Products .descrToggle {
        background-color: $btn;
    }
    .product select {
        background-color: #fff;
    }
    .default .tunnel .step.active .text {
        color: #fff;
    }
    .carousel-control {
        color: $title;
    }
}

/* ==========================================================================
   $RESPONSIVE RULES
   ========================================================================== */

#menuTrigger {
    color: $menu-link;
}

/* LARGE */
@media (min-width: 1200px) {
    .container {
        max-width: $container-width;
    }
}

/* MEDIUM & LARGE (TABLET & DESKTOP) */

@media (min-width: $mbp-up) {
    /* description and CTA button space for medium and big screens */
    .products .product .description {
        margin-bottom: 50px;
    }
}

/* SMALL & MEDIUM (PHONE & TABLET) */

@media (max-width: 992px) {
    .extension_Login .form-vertical.login-form.logout {
        right: 5px;
    }
    #menuTrigger {
        font-size: 1.75rem;
        line-height: 60px;
        width: 100%;
        cursor: pointer;
        text-align: center;
        i {
            margin-left: 5px;
        }
    }
    // bouton suivant sous cart masqué en mobile
    .cart + .tunnel-suivant {
        display: none;
    }
    .col-md-8.extension_Products {
        padding-left: 0;
        padding-right: 0;
    }

    .orderRow {
        .product {
            min-width: 60%;
        }

        .rowTotalWrapper {
            width: 30%;
        }

        .quantity {
            width: 10%;
        }
    }
}
/* patch de suppression du menu fantôme 3.8.3 D */

.container-account > #logged-infos:not(:empty) + #accountMenu {
    display: none;
}

/* ==========================================================================
   $LOADING
   ========================================================================== */

/* Loading composant  à ajouter si admin > 3.9.1 */

.loading-component {
    background-color: #fff;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0px;
    left: 0px;
    display: none;
}

.loading-component .loading-inner {
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-top-color: rgba(0, 0, 0, 0.08);
    border-left-color: rgba(0, 0, 0, 0.08);
    background-color: transparent;
    border-radius: 150px !important;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: loading 1s infinite linear;
    -moz-animation: loading 1s infinite linear;
    -ms-animation: loading 1s infinite linear;
    -o-animation: loading 1s infinite linear;
    animation: loading 1s infinite linear;
    width: 100px;
    height: 100px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}

.loading-component .loading-inner img {
    display: none;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes loading {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes loading {
    0% {
        -ms-transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-o-keyframes loading {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* patch bootstrap 4 -> bootstrap 3 */

html {
    font-size: initial;
}

body {
    line-height: 1.5;
}

/* Suppression de select 2 et Uniform */

/* Céer un compte */

select.date-day,
select.date-month,
select.date-year {
    display: inline-block;
    width: 8em;
    margin-bottom: 0.5em;
}

select.date-day {
    width: 4em;
}

.control-group input[type="checkbox"] {
    display: inline-block;
}

.extension_Form label.checkable-label {
    margin-bottom: 1rem;
}

.newUser .control.formitem {
    margin: 0.75rem auto;
}

.newUser .formitem select {
    width: auto;
}

/* Diminution des notices rouges sur les champs email et mot de passe de l'inscription */

.formitem .required {
    font-size: 0.75rem;
}

/* paragraphe avec required pour les recommandations de saisie mdp par ViJ */

.extension_Form p.required {
    font-size: 0.813em;
    margin: 0 0 5px;
}

/* Information complémentaire */

/* contexte de formatage sur la checkbox */

.popWrapper .checkText input[type="checkbox"] {
    float: left;
    display: block;
    margin-right: 10px;
}

.popWrapper .checkText label {
    overflow: hidden;
    max-width: auto;
    display: block;
}

/* ne pas passer en blanc les quantités de la select */

.extension_Products .product .row.notification select {
    color: #000 !important;
}

/* panneau test footer d'AdM supprimé */

.alert_test {
    display: none;
}

/* Retour de paiement */

//Retour du paiment
.order-module .btn.btn-invoice,
.order-module .btn.btn-tickets {
    &:focus,
    &:hover {
        background-color: #57bd42 !important;
    }
}
/* dropdown connecté, override de bootstrap */

#logged-infos .nav .open > a:hover {
    opacity: 1;
}

/* Optimisation recap commande - alignement des extremités verticales */

.extension_Cart.col-md-12 .cart-summary {
    padding: 10px !important;
}

.extension_Cart.col-md-12 .cart-summary .ticket-info,
.extension_Cart.col-md-12 .cart-summary table {
    width: 100%;
}

.extension_Cart.col-md-12 .total.col-md-6 {
    padding-right: 5px;
}

.extension_Cart.col-md-12 .cart-summary .cgvConditions a {
    text-decoration: underline;
}

/* responsive recap et panier en 3 colonnes sur mobile */
@media (max-width: 480px) {
    .cart-summary .right.show-full-width.hidden-xs-down {
        display: none;
    }
}

.extension_Tunnel #termsModal .modal-body{
    height: 70vh;
}
/* panier dans l'étape de connexion */

.left.product-header.space-wrap.smallDelete.productLabelWidth {
    position: relative;
    left: -10px;
    height: auto;
}

.extension_Cart .cart-summary .products thead th {
    padding-right: 0;
}

.extension_Cart .cart-summary .products thead th:last-child {
    padding-right: 0.5rem;
}

.col-md-4.extension_Cart .cart-summary .products thead th {
    padding-left: 8px;
}

.cart td.product-price {
    padding-left: 1.25rem;
}

/* modale produit plus petite et en hauteur auto */

.modal-content {
    min-height: 100px;
}

.modal-content:after {
    content: " ";
    clear: both;
    display: block;
}

/* crossseling/upselling optimisé */

#productsModal .modal-footer {
    border-top: 0;
}

#productsModal .modal-footer .btn {
    margin-bottom: 20px;
}

.crosssellListe.first,
.upsellListe.first {
    border-top: none;
}

.crosssellProducts,
.upsellProducts {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: #000 dotted 1px;
}

.crosssellListe:last-child .crosssellProducts:last-child,
.upsellListe:last-child .upsellProducts:last-child {
    border-bottom: #000 dotted 1px;
}

.crosssellTitreTableau .col-md-6,
.upsellTitreTableau .col-md-6 {
    padding-left: 30px;
    font-size: 2rem !important;
}

@media (max-width: 400px) {
    .crosssellTitreTableau .col-md-6,
    .upsellTitreTableau .col-md-6 {
        font-size: 1.5rem;
    }
    .crosssellProducts,
    .upsellProducts {
        padding-top: 20px;
        margin-bottom: 20px;
    }
}

.crosssellTitreTableau .col-md-2,
.upsellTitreTableau .col-md-2 {
    padding-right: 25px;
    padding-top: 1.2rem;
}

.crosssellTitreTableau .col-md-2:last-child,
.upsellTitreTableau .col-md-2:last-child {
    padding-right: 32px;
}
@media (min-width: 1024px) {
    .extension_Products .modal-body {
        height: auto !important;
        min-height: 0 !important;
    }
}
@media (min-width: 321px) {
    .modal-content {
        min-height: 0;
    }
    .modal-body {
        height: auto;
    }
}
@media (max-width: 991px) {
    .crosssellTitreTableau .col-md-2,
    .upsellTitreTableau .col-md-2 {
        display: none;
    }
}

@media (min-width: 992px) {
    .crosssellTitreTableau .col-md-2,
    .crosssellListe .price,
    .crosssellListe .qty,
    .crosssellListe .price,
    .upsellTitreTableau .col-md-2,
    .upsellListe .price,
    .upsellListe .qty,
    .upsellListe .price {
        text-align: right;
    }
    .crosssellListe .price,
    .crosssellListe .qty,
    .crosssellListe .price,
    .upsellListe .price,
    .upsellListe .qty,
    .upsellListe .price {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
    }
}

@media (min-width: $mbp-up) {
    .modal .category.col-xs-12.col-sm-4,
    .modal .crosssellTitreTableau .col-md-6,
    .modal .upsellTitreTableau .col-md-6 {
        width: 51.33333333%;
    }
    .modal .crosssellTitreTableau .col-md-6,
    .modal .upsellTitreTableau .col-md-6 {
        width: 68%;
    }
    .modal .price.col-xs-12.col-sm-2,
    .modal .qty.col-xs-12.col-sm-2,
    .modal .crosssellTitreTableau .col-md-2,
    .modal .upsellTitreTableau .col-md-2 {
        width: 10.66666667%;
    }
}

.modal-footer.col-md-12 {
    padding-top: 16px;
    float: none;
}

.modal-footer .btn {
    margin-top: 20px;
}

/* crossseling et upsselling : footer optimisé */
#productsModal .modal-body {
    margin-bottom: 0;
    padding-bottom: 0;
}
#productsModal .modal-body::after {
    clear: both;
    content: "";
    display: block;
}
#productsModal .modal-footer.col-md-12 {
    padding-top: 0;
    float: none;
}
.upsellListe.last {
    margin-bottom: 0;
}

/* optims mobiles : calages des marges latérales et cart recap moins large */

@media (max-width: $mbp) {
    .cart-summary .ticket-info {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .col-xs-12.tunnelWrapper {
        padding-left: 0;
        padding-right: 0;
    }
}


@media (max-width: 543px) {
    .tunnelWrapper > div {
        padding-bottom: 15px;
    }
    .cart-summary {
        padding: 10px 0 !important;
    }
    .col-xs-12.tunnelWrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .bottom .tunnel {
        border-top: 0;
    }
    .default .tunnel {
        margin-bottom: 10px;
        margin-top: 0;
    }
    .extension_Products .product {
        margin: 0 0 10px;
        padding: 10px 15px;
    }
    .extension_Cart .cart-summary .products {
        margin: 0 0 10px;
    }
}

@media (max-width: 479px) {
    .extension_Cart.col-md-12 .cart-summary .products.grouped {
        margin-top: 20px;
        font-size: 0.7em !important;
    }
    .extension_Cart .cart-summary .promo-insurance,
    .extension_Cart.span12 .cart-summary .products.grouped .adhName {
        padding-left: 0 !important;
    }
}

@media (max-width: 359px) {
    .col-xs-12.tunnelWrapper {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
/* iframe popup */

/*************************/

/* IMPORTANT : la classe popup-page traitée ci-dessous doit être renseignée comme classe de la page dans le CMS */

/*************************/

/* white iframe popup body */

@media (min-width: 980px) {
    .modal-dialog {
        width: 980px;
        margin: 30px auto;
    }
    .modal-content {
        min-height: 100px;
    }
    .modal-dialog iframe {
        left: 0 !important;
        min-height: 720px;
    }
}

.popup-body {
    margin: 0 auto !important;
}

body.popup-page {
    background-image: none;
    background-color: #fff;
}

.popup-page #content {
    margin-top: 0;
}

/* optim mise en page du profil */
.extension_Form .left .multi-date,
.extension_Form .left .row-fluid {
    width: auto !important;
    display: inline-block;
}
.extension_Form .left .control label + p.required {
    display: inline-block;
}
.extension_Form .left .control p.required + input {
    margin-left: 170px;
}


/*  RETOUR COMMANDE RESPONSIVE */


/* tooltip full CSS nécessite JS window.USER_CAN_HOVER */
.tooltip-css {
    position: relative;
    display: inline-block;
}
.tooltip-css:focus:after,
.tooltip-css:hover:after {
    background: #333;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    border-bottom-left-radius: 0;
    bottom: 10px;
    color: #fff;
    content: attr(data-original-title);
    left: 20px;
    margin-right: 30%;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 180px;
    font-family: sans-serif;
    font-size: 0.75em;
    line-height: 1.4;
}

/* ==========================================================================
   $MEMBERSHIPS / ADHESIONS RESPONSIVE
   ========================================================================== */

/* ajouter une class adh pour toutes les pages adhésions via le CMS */
.adh.adh-home .content .extension_Menu ul.nav li {
    min-width: 30%;
    width: calc((768px - 100%) * 1000);
    max-width: 100%;
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 20px;
}
.adh.adh-home .content .extension_Menu ul.nav li a {
    display: block;
    padding: 4rem 2rem;
    color: #fff;
    border: solid 2px #666;
    border-radius: 0;
    background: #666;
    transition: all 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
}

@media (min-width: 562px) {
    .col-md-12.extension_Form .formitem label + p.required {
        width: 50%;
        display: inline-block;
        color: #333;
    }
    .col-md-12.extension_Form .left .formitem label + p.required + input {
        margin-left: 168px;
    }
}

.for-required {
    margin-bottom: 2rem;
}

/* code barre */
.adhesionpass-wrapper .row input {
    border-radius: 0 !important;
    border: 1px solid black !important;
}
.adhesionpass-wrapper .searchAdhesion {
    padding: 0.5rem 1rem;
    border-radius: 0 !important;
}

/* inscription/connexion */
.modifUser-wrapper form select,
.createUser-wrapper form select {
    width: 100%;
}
@media (min-width: 562px) {
    .col-md-6.extension_Form p.required {
        font-size: 0.5rem;
    }
    .col-md-6.extension_Form .formitem label + p.required,
    .col-md-12.extension_Form .formitem label + p.required {
        width: 50%;
        display: inline-block;
        color: #333;
    }
    .col-md-6.extension_Form .left .formitem label + p.required + input,
    .col-md-12.extension_Form .left .formitem label + p.required + input {
        margin-left: 168px;
    }
}
@media (min-width: 992px) {
    body[class*="adh-"] .extension_Form .left input {
        width: 50% !important;
    }
    body[class*="adh-"] .extension_Form .left .btn.btn-save.pull-right {
        margin-right: 6rem;
    }
}

.extension_Adhesion form .radio input {
    float: left;
}

/* ajouter un pass en mobile */
@media (max-width: 981px) {
    .bottom-button.add-user {
        float: none !important;
        margin-bottom: 2rem;
    }
}
.extension_Adhesion + .extension_Cart .titleWrapper {
    margin-bottom: 1rem;
}
.extension_Adhesion .titleWrapper .titleModule,
.extension_Adhesion + .extension_Cart .titleWrapper .titleModule {
    background-color: transparent;
    font-weight: bold;
    font-size: 1.25rem;
}
@media (min-width: 982px) {
    .extension_Adhesion .priceButton {
        text-align: right;
    }
}
.extension_Adhesion .priceButton button {
    display: inline-block;
    margin-bottom: 1.5rem;
    padding: 0.4rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    transition: all 200ms linear;
    background: #5b5a54;
    color: #fff;
}
.createUser-wrapper .radio-wrapper label {
    float: none;
}

.extension_Adhesion .radio-wrapper label {
    display: inline-block !important;
    width: 215px !important;
}
@media (max-width: 981px) {
    .createUser-wrapper .radio-wrapper label {
        width: 90% !important;
    }
    .button-create.createUserbutton {
        margin-right: 0 !important;
    }
    .button-cancel.createUserbutton,
    .button-create.createUserbutton {
        margin-top: 1rem;
        display: inline-block;
        vertical-align: baseline;
    }
}
.extension_Adhesion .user-infos .bottom-button {
    margin-top: 1rem;
}
/* images des pass dans le récap */
.cartVertical-wrapper .vertical-product .img-product span.contactPhotoOnImg {
    top: 0;
}
.cartVertical-wrapper
    .vertical-product
    .img-product
    span.contactPhotoOnImg
    img {
    height: auto;
    width: auto;
    max-height: 198px;
    max-width: 120px;
}

.adhesionpass-wrapper .row input,
input.barCode {
    border-radius: 5px !important;
    padding: 0.75rem 1rem;
    border: 1px solid #333 !important;
}
.searchAdhesion {
    padding: 0.75rem 1.5rem;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: $btn;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.adh h3 {
    font-size: 1.5rem;
}

.linkAdhesion-wrapper,
.showNext-wrapper {
    border: none;
}
.adhesion-wrapper.missingInfos {
    margin-left: 15px;
    margin-right: 15px;
}

.linkAdhesion-wrapper input {
    height: auto !important;
}

.button-link.linkAdhesionbutton.bottom-button.btn-save,
.button-search.showNextbutton.bottom-button.btn-save {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0 !important;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.875rem;
}

/* ajout nouvelle personne  - radio nouvelle adresse 15.03.2018 */

.createUser-wrapper .radio-wrapper label {
    float: none;
    min-width: 282px !important;
}

.modifUser-wrapper .radio-wrapper label {
    min-width: 290px !important;
    float: none;
}

.radio-wrapper label {
    display: inline-block !important;
    width: 215px !important;
}
@media (max-width: 981px) {
    .createUser-wrapper .radio-wrapper label {
        width: 90% !important;
    }
    .button-create.createUserbutton {
        margin-right: 0 !important;
    }
    .button-cancel.createUserbutton,
    .button-create.createUserbutton {
        margin-top: 1rem;
        display: inline-block;
        vertical-align: baseline;
    }
}
.extension_Adhesion form .radio input {
    float: left;
}

.extension_Adhesion .titleWrapper .titleModule,
.extension_Cart .titleWrapper {
    margin: 1rem 0;
    padding-top: 1rem;

    font-size: 18px;
    background-color: transparent;
}

.extension_Cart .titleWrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.cart-summary .img-product {
    .contactPhotoOnImg img {
        display: block;
    }
}

//recap indiv
.cart-summary {
    .totalValue td:last-child {
        padding-right: 15px;
    }
}

//recap adhesion
.cart-summary {
    .total-final.adh .totalValue td:last-child {
        padding-right: 3px !important;
    }
}

.cartVertical-wrapper .vertical-product .img-product span.contactPhotoOnImg {
    top: 0;
}

.extension_Adhesion {
    .adhesion-infos .priceButton {
        float: right;
        color: #fff;
    }

    .priceButton button {
        color: #fff;
    }
    .showNext-wrapper {
        padding: 15px;
    }
    .showNext-wrapper .opaque {
        display: none !important;
    }
    .modifUser-wrapper .instructions,
    .createUser-wrapper .instructions {
        float: none;
        margin-top: 10px;
    }

    #modifyUserPicture {
        margin-top: 10px;
    }
}

.totalExVAT td:last-child,
.taxValue td:last-child,
.totalValue td:last-child,
.subtotalValue td:last-child,
.promoValue td:last-child {
    padding-right: 15px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.login-form input[type="text"].m-wrap,
.login-form input[type="password"].m-wrap,
.forget-form input[type="text"].m-wrap {
    line-height: 30px;
    min-height: 30px;
}

select {
    line-height: 30px;
    border-width: 1px;
}

.modifUser-wrapper form select,
.createUser-wrapper form select {
    width: 100%;
}

@media (min-width: 562px) {
    .col-md-12.extension_Form .formitem label + p.required {
        width: 50%;
        display: inline-block;
        color: #333;
    }
    .col-md-12.extension_Form .left .formitem label + p.required + input {
        margin-left: 168px;
    }
}

.for-required {
    margin-bottom: 2rem;
}

/* séparation boutons ajout au panier et editer */
.priceButtonLabel + .priceButton {
    margin-top: 1rem;
}
@media (min-width: 768px) {
    .priceButtonLabel + .priceButton {
        margin-top: 4rem;
    }
}
.user-infos .bottom-button.detach {
    margin-top: 1rem;
}

/* ajouter un pass en mobile */
@media (max-width: 767px) {
    .bottom-button.add-user {
        float: none !important;
    }
}

/* bouton compléter */
.msgContainer span {
    cursor: pointer;
    text-align: center;
    line-height: 33px;
    width: 180px !important;
    height: 35px;
    border: 1px solid #18a4dc;
    opacity: 1;
    display: inline;
    padding: 0.5rem 1rem;
}

/* images des pass dans le récap */
.cartVertical-wrapper .vertical-product .img-product span.contactPhotoOnImg {
    top: 0;
}
.cartVertical-wrapper
    .vertical-product
    .img-product
    span.contactPhotoOnImg
    img {
    height: auto;
    width: auto;
    max-height: 198px;
    max-width: 120px;
}

.titleWrapper .titleModule {
    background: none;
}

.extension_Adhesion {
    .infosMissing-message {
        clear: both;
    }
}

.allMembers-wrapper.vertical .adhesion-wrapper .bottom-button {
    border-color: transparent;
}

.extension_AdhesPass {
    .button-search.useruserEchangeCodebutton,
    .button-cancel.useruserEchangeCodebutton {
        width: 200px;
        display: inline-block;
        padding: 5px 20px;
        background: #28a745;
        color: #fff;
        margin-top: 30px;
    }

    .button-cancel.useruserEchangeCodebutton {
        background: #dc3545;
    }
}

.extension_Adhesion .priceButton button {
    background: $btn;
    border-color: $btn;
    border-style: solid;
}

#copyright {
    background: #000;
    text-align: center;
}
#copyright a {
    display: inline-block;
    padding: 0.6rem 0 0.5rem 0;
    width: 100%;
    color: #fff;
    font-style: italic;
}
#copyright svg {
    vertical-align: middle;
    margin-left: 0.2rem;
    margin-top: -2px;
}

@if ($copyright == false) {
    #copyright {
        display: none;
    }
}

/* fix problèmes photos adhesions =*/
.adh.adh-recap {
    .img-product {
        img {
            display: none;
        }

        .contactPhotoOnImg {
            top: 0 !important;
            img {
                display: block;
            }
        }
    }
}

/* ==========================================================================
   $EVOLUTIONS
   ========================================================================== */

// Dette technique globale obligatoire
@import "../../../../../app/deployment_sources/common/evolutions/patches-dette-technique-globale";

// Ajoute la compatibilité IOS6, IOS7, Safari Mac 5 à 8 et IE10
// Utiliser en complément l'option auto-prefix sur le compilateur lors du traitement du fichier global.scss (avec Koala ou Gulp)
@import "../../../../../app/deployment_sources/common/evolutions/old-browsers";

// Old browser warning like IE, IE mobile and Blackberry...
//@import "../../../../../app/deployment_sources/common/evolutions/old-browsers-warning";

// Mise en forme du message d'alerte en cas de navigation sans JavaScript
@import "../../../../../app/deployment_sources/common/evolutions/no-script";

// RGPD / GDPR : si ajout des questions vie privée en fin d'inscription
@import "../../../../../app/deployment_sources/common/evolutions/rgpd";

// Nouveau calendrier Flex avec sélection du mois en cours
// Garder commenter pour une nouvelle charte, décommenter pour patcher un vieux site
//@import "../../../../../app/deployment_sources/common/evolutions/calendar";

// Nouveau calendrier Flex avec sélection du mois en cours et année précedente disabled
//@import "../../../../../app/deployment_sources/common/evolutions/calendar-year-month-active";

// Calendar prix cacher et centrage du contenue date
//@import "../../../../../app/deployment_sources/common/evolutions/calendar-price-hide";

// Calendrier avec horaires sur la même page
// Ajustement des tailles de jours de semaine, jour du mois et prix si sessions à droite seulement si les sessions sont intégrées au calendar
//@import "../../../../../app/deployment_sources/common/evolutions/calendar-sessions-font-adjust";
@import "../../../../../app/deployment_sources/common/evolutions/calendar-sessions-side-borders";


/* fin du base */
